import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import BlogPostCard from '../components/blogPostCard';
import { login, isUserAuthenticated } from '../utils/auth';

class PersonTemplate extends React.Component {
  constructor(props) {
    const { data } = props;
    super(props);

    this.state = {
      data,
      isLoading: true,
      isAuthenticated: false,
    };
  }

  async componentDidMount() {
    const isAuthenticated = await isUserAuthenticated();
    this.setState({ isAuthenticated, isLoading: false });
  }

  render() {
    const { data, isLoading, isAuthenticated } = this.state;
    if (isLoading) {
      return <p>Loading person...</p>;
    }

    if (!isAuthenticated) {
      login();
      return <p>Redirecting to login...</p>;
    }

    const person = data.contentfulPerson;
    const numBlogPosts = data.allContentfulBlogPost.edges.length;

    const noBlogPosts = (
      <h4 className="mt-3">No blog posts by {person.name} ... yet</h4>
    );
    const hasBlogPosts = (
      <>
        <h4 className="mt-5">
          {numBlogPosts} Blog Posts by {person.name}
        </h4>
        <div className="row mt-3">
          {data.allContentfulBlogPost.edges.map(({ node }) => {
            const props = {
              imageTitle: node.mainImage.title,
              imageUrl: node.mainImage.file.url,
              postDate: node.date,
              postSlug: node.fields.fullSlug,
              postTitle: node.title,
              postTwoSentenceSummary: node.twoSentenceSummary,
            };

            return (
              <div key={node.id} className="col-12 col-sm-6 col-lg-4 mb-3">
                <BlogPostCard {...props} />
              </div>
            );
          })}
        </div>
      </>
    );

    const props = {
      title: `${person.name} | The Cao Farm`,
      jumbotronTitle: person.name,
      jumbotronCaption: `Welcome to ${person.name}'s page!`,
    };

    return (
      <Layout {...props}>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-7">
            <h3>Bio</h3>
            <p>
              {person.bio
                ? person.bio.bio
                : `${person.name} doesn't have a bio ... yet`}
            </p>
          </div>
          <div className="col-12 col-sm-6 col-md-5">
            <img
              src={'https:' + person.profilePicture.file.url}
              className="img-fluid img-thumbnail"
              alt={person.profilePicture.file.title}
            />
          </div>
        </div>
        {numBlogPosts ? hasBlogPosts : noBlogPosts}
      </Layout>
    );
  }
}

export default PersonTemplate;

export const PersonAndAuthorshipQuery = graphql`
  query PersonAndAuthoredBlogPosts($contentfulId: String!) {
    allContentfulBlogPost(
      filter: { author: { contentful_id: { eq: $contentfulId } } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          date(formatString: "DD MMMM, YYYY")
          mainImage {
            title
            file {
              url
            }
          }
          title
          twoSentenceSummary
          fields {
            fullSlug
          }
        }
      }
    }

    contentfulPerson(contentful_id: { eq: $contentfulId }) {
      bio {
        bio
      }
      name
      profilePicture {
        file {
          url
        }
        title
      }
    }
  }
`;
